var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",staticClass:"container",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.country && (_vm.isInRole('11') || _vm.isInRole('12')))?_c('v-card',{},[_c('v-card-title'),_c('v-card',{staticClass:"pa-5",attrs:{"flat":""}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('country.countryName'),"rules":_vm.rules},model:{value:(_vm.country.countryName),callback:function ($$v) {_vm.$set(_vm.country, "countryName", $$v)},expression:"country.countryName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',{staticClass:"elevation-1",style:(_vm.$vuetify.theme.isDark
                      ? 'background-color:#363636'
                      : 'background-color:whitesmoke')},[_c('v-row',{staticClass:"ma-2 2",attrs:{"no-gutters":"","elevation":"5"}},[_c('v-col',{staticClass:"button mt-2",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.$t("city.cities"))+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right",attrs:{"color":"primary","dark":""},on:{"click":_vm.addItem}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus")])],1)]}}],null,false,2322366851)},[_c('span',{},[_vm._v(" "+_vm._s(_vm.$t("city.addCity"))+" ")])])],1)],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-list',_vm._l((_vm.cities),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('city.cityName'),"rules":_vm.rules},model:{value:(item.cityName),callback:function ($$v) {_vm.$set(item, "cityName", $$v)},expression:"item.cityName"}})],1)],1)],1)],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red lighten-2"},on:{"click":function($event){return _vm.removeItem(i)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("remove"))+" ")])])],1)],1)}),1)],1),_c('v-divider')],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"green darken-1","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"to":"/countries","color":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t("backToList"))+" ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }